import React, { Suspense, lazy } from "react";
import { Switch, Route, RouteComponentProps, withRouter } from "react-router-dom";
import { Header } from '@components/Header';

const HomePage = lazy(
    () => import(/*webpackChunkName:'Home_page'*/ '@pages/Home')
)
const AboutPage = lazy(
    () => import(/*webpackChunkName:'About_page'*/ '@pages/About')
)
const StrategyPage = lazy(
    () => import(/*webpackChunkName:'Strategy_page'*/ '@pages/Strategy')
)
const ExamplePage = lazy(
    () => import(/*webpackChunkName:'Example_page'*/ '@pages/Example')
)

const Layout: React.FC = () => {
    return <div className="layout-main">
        <Header />
        <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/about" exact component={AboutPage} />  
            <Route path="/strategy" exact component={StrategyPage} />  
            <Route path="/example" exact component={ExamplePage} />  
        </Switch>
    </div>
}

export default Layout;