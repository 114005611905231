import React, { Component } from "react";

interface IErrorBoundaryProps {
    error: any // 异常显示组件
    [any: string]: any;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState>{
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false
        }
    }
    static getDerivedStateFromError () {
        return {
            hasError: true
        }
    }
    componentDidCatch (error: Error, errorInfo: React.ErrorInfo): void {
        console.error(error);
        console.error(errorInfo)
    }
    render () {
        const { hasError } = this.state;
        const {
            children,
            error: ErrorComponent,
            ...props
        } = this.props;

        return <>
            { hasError ? <ErrorComponent {...props} /> : children }
        </>
    }
}