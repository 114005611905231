import React, { Suspense, lazy } from "react";
import * as ReactDnD from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { Exception } from '@components/index';

import { HashRouter as Router, Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import Layout from './layout_main';

type ComponentDecorator<P = any> = <T extends React.ComponentClass<P>>(WrappedComponent: T) => T;

export interface RouteProps extends RouteComponentProps {};
export const route: ComponentDecorator = withRouter as any;

export default () => {
    return <ErrorBoundary error={Exception}>
        <ReactDnD.DndProvider backend={Backend}>
            <Suspense fallback={<div />}>
                <Router>
                    <Switch>
                        <Route path="/" component={Layout} />
                    </Switch>
                </Router>
            </Suspense>
        </ReactDnD.DndProvider>
    </ErrorBoundary>
}