import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
// import logo from '@/assets/images/logo.png';
import './index.scss';

const navs: NavItem[] = [
    { title: '首页', path: '/' },
    { title: '关于我们', path: '/about' },
    // { title: '运营策略', path: '/strategy' },
    // { title: '合作品牌', path: '/cooperation' },
    // { title: '优秀案例', path: '/example' }
]

export const Header: React.FC = () => {
    const [pathname, setPathname] = useState<string>('');
    const [mbNav, setMbNav] = useState<boolean>(false);
    const [right, setRight] = useState<number | undefined>(undefined);
    const [opacity, setOpacity] = useState<number>(0.6);
    const history = useHistory<History>();
    const background = `rgba(0, 0, 0, ${opacity})`;
    // 导航跳转
    const handleClick = (nav: NavItem) => {
        setMbNav(false);
        history.push(nav.path);
    }
    useEffect(() => {
        const name = window.location.hash.replace('#', '');
        setPathname(name);
        navs.find((item, index) => {
            if (item.path === name) {
                 setRight((navs.length - index - 1) * 136);
                 return true;
            } else {
                return false;
            }
        })
        history.listen((e: any) => {
            const pathname = e?.pathname;
            setPathname(pathname);
            navs.find((item, index) => {
               if (item.path === pathname) {
                    setRight((navs.length - index - 1) * 136);
                    return true;
               } else {
                   return false;
               }
            })
        })

    }, []);

    return <div className="main-header" style={{ background: background }}>
        <div className="container">

            <ul className="header-nav">
                {
                    navs.map(item => {
                        return <li key={item.path} onClick={() => handleClick(item)}>
                            { item.title }
                        </li>
                    })
                }
                { typeof right === 'number' && <li style={{ right }} className="active"></li> }
            </ul>
            <div className={mbNav ? "mb-menu active" : 'mb-menu' } onClick={() => setMbNav(nav => !nav)}>
                <div />
                <div />
                <div />
            </div>
            <ul className="mb-nav" style={{ right: !mbNav ? '-100vw' : 0 }}>
                {
                    navs.map(item => {
                        return <li key={item.path} className={pathname === item.path ? 'active' : ''} onClick={() => handleClick(item)}>
                            { item.title }
                        </li>
                    })
                }
            </ul>
        </div>
    </div>
}